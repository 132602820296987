import React from "react";

export default ({ color, height }) => (
	<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M33 9.33333H25.6667V5.66667C25.6667 3.63167 24.035 2 22 2H14.6667C12.6317 2 11 3.63167 11 5.66667V9.33333H3.66667C1.63167 9.33333 0.0183333 10.965 0.0183333 13L0 33.1667C0 35.2017 1.63167 36.8333 3.66667 36.8333H33C35.035 36.8333 36.6667 35.2017 36.6667 33.1667V13C36.6667 10.965 35.035 9.33333 33 9.33333ZM22 9.33333H14.6667V5.66667H22V9.33333Z" fill="#333333" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M31.4616 14.7986C31.1482 19.0267 29.7352 24.9336 24.3025 24.9336C23.1332 24.9336 22.0594 24.5995 21.1525 24.0268C20.5798 25.5541 19.7446 28.2268 19.3389 31.0905C21.6121 31.4874 23.558 32.4981 24.8775 33.8751C24.4179 33.9572 23.9448 34 23.4616 34H9.29538C10.7085 32.4685 12.9081 31.3577 15.4491 31.0189C15.2344 29.3723 14.8287 27.6302 14.2798 26.1745C13.4923 26.8427 12.49 27.2723 11.3684 27.32C8.64799 27.4393 6.4048 24.9575 6.4048 22.237V20.1848C6.4048 19.4927 6.28549 18.8245 6.07071 18.2041C5.78435 17.3927 6.4048 16.5575 7.2639 16.6291C10.3662 16.8439 15.8548 17.87 15.9503 22.5473C15.9503 23.2393 15.8071 23.8836 15.5446 24.4564C14.5423 23.6927 13.4923 23.0245 12.3707 22.5473C13.7548 23.5734 15.64 25.8166 17.1434 28.7518C18.7423 24.8859 21.1525 20.9484 24.3025 17.7745C22.4889 19.0155 20.7946 20.4473 19.2196 22.0939C18.5991 21.0916 18.265 19.8745 18.3605 18.5859C18.5514 15.3882 21.415 13.0018 24.6128 13.0018H26.6889C26.7461 13.0018 26.8026 13.0012 26.8585 13H30.4616C31.0139 13 31.4616 13.4477 31.4616 14V14.7986Z" fill="#DAF4DE" />
		<path d="M34.1937 32.6682C33.437 31.414 31.8067 31.0107 30.5525 31.7674C29.2983 32.5242 28.895 34.1544 29.6517 35.4087C30.4085 36.6629 32.0387 37.0662 33.293 36.3095C34.5472 35.5527 34.9505 33.9225 34.1937 32.6682Z" fill="white" />
		<path d="M32.1078 31.5188C31.4886 30.4926 30.1548 30.1627 29.1286 30.7819C28.1024 31.401 27.7724 32.7349 28.3916 33.761C29.0108 34.7872 30.3446 35.1172 31.3708 34.498C32.397 33.8789 32.7269 32.545 32.1078 31.5188Z" fill="#FFB000" />
		<path d="M32.7397 2.19235C31.3191 1.57794 29.6694 2.23148 29.055 3.65207C28.4406 5.07265 29.0941 6.72234 30.5147 7.33674C31.9353 7.95115 33.585 7.29761 34.1994 5.87702C34.8138 4.45644 34.1603 2.80675 32.7397 2.19235Z" fill="#333333" />
		<path d="M30.2401 2.48328C29.0778 1.98059 27.728 2.5153 27.2253 3.6776C26.7226 4.83989 27.2573 6.18964 28.4196 6.69233C29.5819 7.19502 30.9317 6.66031 31.4344 5.49801C31.9371 4.33572 31.4024 2.98597 30.2401 2.48328Z" fill="#73A77B" />
		<path d="M3.35997 7.34349C4.44009 8.25303 6.05303 8.11476 6.96258 7.03464C7.87212 5.95452 7.73384 4.34158 6.65373 3.43203C5.57361 2.52249 3.96067 2.66077 3.05112 3.74088C2.14157 4.821 2.27985 6.43394 3.35997 7.34349Z" fill="#333333" />
		<path d="M5.62058 7.74499C6.50431 8.48917 7.82399 8.37603 8.56817 7.4923C9.31234 6.60856 9.19921 5.28888 8.31547 4.54471C7.43174 3.80054 6.11206 3.91367 5.36789 4.79741C4.62371 5.68114 4.73685 7.00082 5.62058 7.74499Z" fill="#F76E9C" />
	</svg>



);
